<template>
  <div class="subscription-cancel-button" @click="openCancelModal">
    <slot>
      <a>Cancel Plan</a>
    </slot>
  </div>
</template>

<script>
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';

import SubscriptionCancelConfirm from './SubscriptionCancelConfirm';

export default {
  props: {
    subscription: Object,
  },
  methods: {
    openCancelModal() {
      let modalParams = {classes: 'clear_modal', name: 'subscription-cancel-confirm'};
      const props = {subscription: this.subscription, cancelRequestedHandler: this.cancelRequestedHandler};
      this.$modal.show(SubscriptionCancelConfirm, props, modalParams);
      AnalyticsAmplitudeHandler.trackCancelStandardSubscriptionClick();
    },
    cancelRequestedHandler(deactivated) {
      this.$router.push({name: 'subscription-canceled', query: {expire_on: this.subscription.expire_on, deactivated}});
    },
  },
  name: 'SubscriptionCancelButton',
};
</script>

<style lang="scss" scoped></style>
