<template>
  <div class="delete-confirm-modal-content">
    <close-button @click="cancelClick"></close-button>
    <h5 class="heading">We're sorry to see you go!</h5>
    <div class="paragraph">
      Your {{ standardPlanName }} membership is valid until {{ expireDate }}. If you don't want to continue after this
      date, please click "{{ confirmLabel }}" below.
    </div>
    <div class="paragraph">
      <b>Are you sure?</b> By switching to the {{ basicPlanName }} (free) plan, you'll lose access to all Overseas and
      China collections, OCR tools, and dictionary tools.
    </div>
    <div class="buttons-list">
      <mcr-button :disabled="loading" @click="cancelClick">Keep my membership</mcr-button>
      <mcr-button class="dark-grey" :loading="loading" @click="okClick">{{ confirmLabel }}</mcr-button>
    </div>
  </div>
</template>

<script>
import CloseButton from '@common/elements/buttons/closeButton';
import McrButton from '@common/elements/buttons/mcrButton';
import moment from 'moment';

export default {
  props: {
    subscription: Object,
    cancelRequestedHandler: Function,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    expireDate() {
      return moment(this.subscription.expire_on).format('MMMM DD, YYYY');
    },
    confirmLabel() {
      return 'Cancel my membership';
    },
    basicPlanName() {
      const plan = this.$store.getters.subscriptionPlansState.find(plan => plan.is_basic_plan);
      return plan ? plan.display_text : 'Basic';
    },
    standardPlanName() {
      return this.subscription.plan.display_text;
    },
  },
  methods: {
    cancelClick() {
      this.$emit('close');
    },
    okClick() {
      this.loading = true;
      this.$store
        .dispatch('cancelSubscriptionAction', {id: this.subscription.id})
        .then(response => {
          if (this.cancelRequestedHandler) {
            this.cancelRequestedHandler(response.is_deactivated);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  components: {CloseButton, McrButton},
};
</script>

<style lang="scss" scoped>
.delete-confirm-modal-content {
  padding: 20px 50px 30px;
  max-width: 440px;

  .heading {
    margin-bottom: 30px;
  }

  .paragraph {
    margin-bottom: 20px;
  }

  .buttons-list {
    margin-top: 60px;
    display: flex;
    flex-direction: column;

    > .mcr-button {
      margin-bottom: 20px;
    }
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    max-width: 100vw;
  }
}
</style>
