<template>
  <div class="forgot-pwd-container">
    <div class="readable_content account-form-container">
      <h5>Reset Password</h5>
      <p class="subtitle">Please enter the email used to create your account.</p>
      <div class="account-form">
        <input type="email" placeholder="Email" v-model="email" @keyup.enter="_confirm()" />
        <transition name="fade">
          <p class="error" v-if="errorMessage">
            {{ errorMessage }}
          </p>
        </transition>
        <mcr-button @click="_confirm" :loading="submitting">
          <span>Confirm</span>
        </mcr-button>
      </div>
      <div class="action_links">
        <router-link :to="{name: 'login'}"> Back to Login </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import mcrButton from '@common/elements/buttons/mcrButton';
import {isEmailValid} from '@common/utils/utils';

export default {
  components: {mcrButton},
  name: 'root-forgotpwd',
  data() {
    return {
      email: '',
      errorMessage: '',
      submitting: false,
    };
  },
  methods: {
    _confirm() {
      if (!this.email || this.email.length <= 0 || !isEmailValid(this.email)) {
        this.errorMessage = 'Please enter your email in a supported format.';
        return;
      }

      if (this.submitting) {
        return;
      }

      this.submitting = true;

      this.errorMessage = '';

      this.network.member
        .sendForgotPwdEmail({
          email: this.email,
        })
        .then(res => {
          this.errorMessage = '';
          if (!res || res._reason || res._reason.length > 0) {
            this.errorMessage = res._reason;
            return;
          }

          console.debug('res', res);

          // TODO: go to tell user check email

          this.$router.push({
            name: 'forgot-email-sent',
            params: {
              email: this.email,
            },
          });
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import './login';
</style>
