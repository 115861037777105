<template>
  <div class="password-input">
    <input
      :type="inputType"
      placeholder="Password"
      :value="password"
      :class="{'has-error': error}"
      @input="$emit('input', $event)"
      @keyup.enter="$emit('enter')"
    />
    <div class="icon-container" @click="toggleIsVisible">
      <eye-off-icon v-if="isVisible"></eye-off-icon>
      <eye-icon v-else></eye-icon>
    </div>
  </div>
</template>

<script>
import EyeIcon from 'vue-material-design-icons/Eye';
import EyeOffIcon from 'vue-material-design-icons/EyeOff';

export default {
  props: {
    password: String,
    error: Boolean,
  },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    inputType() {
      return this.isVisible ? 'text' : 'password';
    },
  },
  methods: {
    toggleIsVisible() {
      this.isVisible = !this.isVisible;
    },
  },
  components: {EyeIcon, EyeOffIcon},
};
</script>

<style lang="scss" scoped>
.password-input {
  position: relative;
  display: flex;
  .icon-container {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    cursor: pointer;
    opacity: 0.6;
    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }
  input {
    width: 100%;
    &::placeholder {
      position: relative;
      top: 1px;
      left: 2px;
      color: #aaa;
    }
  }
}
</style>
