<template>
  <div class="current-subscriptions profile-edit-item">
    <span class="label">Current Plan</span>
    <div class="edit-item-content">
      <div v-if="activeSubscription" class="subscription-details">
        <div class="plan">
          <div class="name">{{ activeSubscription.plan.display_text }} ({{ activePlanCost }})</div>
          <div class="actions">
            <subscription-plans-link label="Compare Plans" :is-button="false" />
            <span class="separator" v-if="canCancel"> | </span>
            <subscription-cancel-button :subscription="activeSubscription" v-if="canCancel">
              <a>Cancel</a>
            </subscription-cancel-button>
          </div>
        </div>
        <div class="subscription-disclaimer" v-if="showSubscriptionDisclaimer">
          <span v-if="trialEndsDisplay">Free trial ends {{ trialEndsDisplay }}. </span>
          <span v-if="activeSubscription.cancel_at_period_end">Expires: {{ expireOnDisplay }}</span>
          <span v-else-if="!activeSubscription.invoice_retry_on">Next bill: {{ expireOnDisplay }}</span>
        </div>
        <div class="error-message" v-if="activeSubscription.invoice_retry_on">
          <div class="heading"><alert-icon :size="30" /> <b>Your membership is currently PAUSED.</b></div>
          <div class="description">
            <div>We haven’t been able to process your most recent payment.</div>
            <div>Please update your billing info using the button below.</div>
            <div class="has-margin-top">
              We will try to charge your card again on <b>{{ invoiceRetryDisplay }}</b
              >. If we cannot process your payment after 3 tries, your
              {{ activeSubscription.plan.display_text }} membership will be downgraded to {{ basicPlanName }}
              (Free).
            </div>
          </div>
        </div>
      </div>
      <div v-else class="subscription-details">
        <div class="plan">
          <div class="name">{{ basicPlanName }} (Free)</div>
          <div class="actions"><subscription-plans-link label="Compare Plans" :is-button="false" /></div>
        </div>
        <div class="subscription-disclaimer">
          <span v-if="downgradedDisplay">Downgraded: {{ downgradedDisplay }}</span>
        </div>
        <div class="error-message" v-if="downgradedDisplay && recentSubscription.invoice_retry_on">
          <div class="heading">
            <alert-icon :size="30" />
            <b>Your {{ recentSubscription.plan.display_text }} membership has been cancelled.</b>
          </div>
          <div class="description">
            We couldn't process your payment. To re-activate your membership, you can
            <subscription-plans-link :label="paymentFailSubscriptionPlansLinkLabel" :is-button="false" />.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import AlertIcon from 'vue-material-design-icons/Alert';

import SubscriptionCancelButton from '@/components/common/SubscriptionCancelButton';
import SubscriptionPlansLink from '@/components/common/buttons/SubscriptionPlansLink';

export default {
  components: {SubscriptionCancelButton, SubscriptionPlansLink, AlertIcon},
  props: {
    subscriptions: Array,
    plans: Array,
  },
  computed: {
    activeSubscription() {
      const status = this.recentSubscription && this.recentSubscription.status;
      return status === 'active' || status === 'payment_failed' ? this.recentSubscription : null;
    },
    recentSubscription() {
      return this.subscriptions.length ? this.subscriptions[0] : null;
    },
    activePlanCost() {
      if (!this.activeSubscription) {
        return;
      }
      if (this.activeSubscription.plan.billing_period === 'free') {
        return 'Free';
      }
      const cost = this.activeSubscription.plan.cost;
      const period = this.activeSubscription.plan.billing_period === 'monthly' ? 'mo' : 'year';
      return `$${cost}/${period}`;
    },
    showSubscriptionDisclaimer() {
      return this.activeSubscription ? this.activeSubscription.plan.billing_period !== 'free' : false;
    },
    trialEndsDisplay() {
      return this.activeSubscription.trial_ends_on ? this.getDateDisplay(this.activeSubscription.trial_ends_on) : null;
    },
    expireOnDisplay() {
      return this.getDateDisplay(this.activeSubscription.expire_on);
    },
    invoiceRetryDisplay() {
      return this.getDateDisplay(this.activeSubscription.invoice_retry_on);
    },
    downgradedDisplay() {
      if (!this.activeSubscription && this.recentSubscription) {
        return this.getDateDisplay(this.recentSubscription.expire_on);
      }
    },
    canCancel() {
      if (!this.activeSubscription) {
        return false;
      }
      return !this.activeSubscription.cancel_at_period_end && this.activeSubscription.plan.billing_period !== 'free';
    },
    basicPlanName() {
      const plan = this.plans.find(plan => plan.is_basic_plan);
      return plan ? plan.display_text : 'Basic';
    },
    paymentFailSubscriptionPlansLinkLabel() {
      return `renew your ${this.recentSubscription.plan.display_text} membership`;
    },
  },
  methods: {
    getDateDisplay(date) {
      return moment(date).format('MMMM DD, YYYY');
    },
  },
  name: 'CurrentSubscriptions',
};
</script>

<style lang="scss" scoped>
@import './profile';

.edit-item-content {
  flex-grow: 1;

  .subscription-details {
    display: flex;
    flex-direction: column;

    .plan {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .name {
        margin-right: 10px;
      }
    }

    .actions {
      > * {
        display: inline;
      }
    }

    .subscription-disclaimer {
      color: $supplemental-text-color;
      font-style: italic;
      span {
        display: inline-block;
        margin-top: 15px;
        margin-right: 5px;
      }
    }

    .error-message {
      margin-top: 20px;
      padding: 20px;
      background-color: $background-alternate-color;
      color: $supplemental-text-color;

      .heading {
        display: flex;
        align-items: center;

        .material-design-icon {
          margin-right: 10px;
        }
      }
      .description {
        max-width: 500px;
        padding: 15px 0px 15px 40px;

        .has-margin-top {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
