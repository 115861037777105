<template>
  <facebook-sign-in-button @sign-in="facebookSignIn">
    <span>Log in with Facebook</span>
  </facebook-sign-in-button>
</template>

<script>
import FacebookSignInButton from '@/base/elements/social-buttons/FacebookSignInButton';

export default {
  methods: {
    facebookSignIn(r) {
      this.$store
        .dispatch('facebookSignInAction', r.authResponse.accessToken)
        .then(response => {
          this.$emit('success', response);
        })
        .catch(error => {
          this.$emit('fail', error);
        });
    },
  },
  components: {FacebookSignInButton},
};
</script>

<style lang="scss" scoped>
.facebook-sign-in-button-container {
  background-color: #4267b2;
  color: white;
  height: 50px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 10px;

  .text {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
  }
}
</style>
