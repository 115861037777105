<template>
  <div class="emailing-settings">
    <elastic-email-subscription-form
      :hide-lists="false"
      :ref="eeSubscriptionForm"
      :active-lists-ids="activeEmailListsIds"
      :emailLists="settings"
    >
      <div slot="header" class="header">Only send me emails for the following checked categories:</div>
      <div slot="form" style="display: none">
        <input required="" name="email" type="email" placeholder="Your Email" :value="email" />
      </div>
    </elastic-email-subscription-form>
    <mcr-button class="update-button" :loading="loading" @click="updateClick">Update</mcr-button>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';

import ElasticEmailSubscriptionForm from '@/components/common/forms/ElasticEmailSubscriptionForm';

export default {
  props: {
    settings: Array,
    email: String,
    loading: Boolean,
  },
  data() {
    return {
      eeSubscriptionForm: 'ee-subscription-form',
    };
  },
  computed: {
    activeEmailListsIds() {
      return this.settings.filter(setting => setting.value).map(setting => setting.list_public_id);
    },
  },
  methods: {
    updateClick() {
      this.$emit('submit', this.$refs[this.eeSubscriptionForm]);
    },
  },
  components: {McrButton, ElasticEmailSubscriptionForm},
};
</script>

<style lang="scss" scoped>
.emailing-settings {
  .header {
    margin-bottom: 15px;
  }
  .update-button {
    margin-top: 10px;
  }
}
</style>
